import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, } from 'rxjs';
import {  map, shareReplay, take, tap, withLatestFrom, filter } from 'rxjs/operators';
import { STSActions } from 'src/app/@store/actions/sts.actions';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { claimTypes } from 'src/app/appsettings';
import { Claim } from 'src/app/models/claims.model';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class PtEeGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private rt: Router,
    private api: ApiService,
    private auth: AuthService,
    private config: ConfigService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const config = this.config._envConfig;
      if (!(config.WhiteLabelEnabled.toLowerCase() == 'true' && config.WhiteLabelName.toLowerCase() == 'ee')) {
        return true;
      }
      return  this.store.select(StsSelectors.getProfile).pipe(
        filter(c => Array.isArray(c) && c.length>0),
        withLatestFrom(this.store.select(StsSelectors.isAppAccessUser)),
        map(([claims, isAppAccessUser]) =>  this.shouldCompleteRequiredEE(claims, isAppAccessUser)),
        withLatestFrom(
          this.store.select(StsSelectors.getbaseProfileUrl),
          this.auth.userInfo$
        ),
        map(([redirect, accountsUrl, u]) => {
          if (redirect)  window.location.href = `${accountsUrl}/registration/complete_registration_ptee?userid=${u.sub}`;
          return true;
        }),
      )
  }

  fetchClaims(sub:string) {
    return this.api.get<Claim[]>(`/manage/accounts/${sub}`).pipe(
      take(1),
      shareReplay(1),
      tap(claims => this.saveClaims(claims)),
    );
  }

  saveClaims(user: Claim[]): void {
    this.store.dispatch(
      STSActions.loadProfileSuccess({
        claims: user,
      })
    );
  }

  private shouldCompleteRequiredEE(claims: Claim[], isAppAccessUser: boolean): boolean {
    const hasNotificationLang = claims?.some(c => c.claimType === claimTypes.EE_NOTIFICATION_LANGUAGE && (c?.claimValue!='' && c.claimValue!=null));
    return !hasNotificationLang && isAppAccessUser;
  }
}
