<div class="navbar-wrapper" id="navbar-wrapper">
    <syn-synlab-header-switch-profile *ngIf="useNewNav$| async" (onLogout)="logout()"></syn-synlab-header-switch-profile>
</div>

<div class="page-header-wrap">
  <app-header *ngIf="!(useNewNav$| async)"></app-header>
  <div class="progress"  *ngIf="loadingBar$ | async">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"></div>
  </div>
</div>
<div class="page-content-wrap" [class.ee-tenant]="isEETenant$|async">
  <div class="s-container">
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf="isDKTenant$|async" class="page-footer-wrap">
  <app-footer></app-footer>
</div>

<app-popup-unauthorized></app-popup-unauthorized>
<router-outlet name="fi-cookie-module"></router-outlet>
