import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, skipWhile, map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { claimTypes } from "src/app/appsettings";
import { Claim } from "src/app/models/claims.model";
import { GpEnrollee } from "src/app/shared/components/gp-agreement-popup/gp-enrollee.model";
import { GpEnrollmentNoticeComponent } from "src/app/shared/components/gp-enrollment-notice/gp-enrollment-notice.component";
import { checkIfCanEnroll, getAgreement, getEnrolleeInfo, isGenePlanetEnrolled } from "src/app/shared/utils/geneplanet.utils";
import { ApiService } from "../api/api.service";
import { AuthService } from "../auth/auth.service";
import { ConfigService } from "../config/config.service";
import { LoggerService } from "../logger.service";

@Injectable({
  providedIn: "root",
})
export class PreventionWellnessGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private rt: Router,
    private auth: AuthService,
    private api: ApiService,
    private dialog: MatDialog,
    private config: ConfigService,
    private logr: LoggerService,
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    if (this.config._envConfig.EnablePreventionWellness.toLowerCase() != 'true') {
      return this.rt.createUrlTree(["/not-found"]);
    }

    return this.auth.userInfo$.pipe(
      skipWhile(() => this.auth.signinRedirectDone$.getValue() === false),
      take(1),
      switchMap(userInfo => this.fetchClaims(userInfo.sub)),
      map(claims => ({ isEnrolled: isGenePlanetEnrolled(claims), claims })),
      switchMap(({ isEnrolled, claims }) => {
        if (isEnrolled) {
          this.logr.log('User is already enrolled, allowing prevention wellness.')
          return of(true);
        }
        this.logr.log('Checking if user can be enrolled');
        const synlabId = claims.find(c => c.claimType == claimTypes.SYNLAB_ID)?.claimValue;
        return checkIfCanEnroll(this.store.pipe(select(StsSelectors.getApiEndpoint)), this.api, this.store, synlabId).pipe(
          tap(canEnroll => !canEnroll && this.logr.log('Enrollment not allowed or API failed.') ),
          switchMap(canEnroll => {
            if(canEnroll) {
              return this.promptEnrollmentAgreement(claims);
            }
            return of(false);
          })
        )
      }),
      map(allow => allow ? true : this.rt.createUrlTree(['/']))
    )
  }

  fetchClaims(sub:string) {
    return this.api.get<Claim[]>(`/manage/accounts/${sub}`).pipe(
      take(1),
      shareReplay(1),
      catchError(() => of([]))
    )
  }

  promptEnrollmentAgreement(claims:Claim[]) {
    let enrollee: GpEnrollee = getEnrolleeInfo(claims);
    let agreement = getAgreement(claims);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      enrollee,
      agreement
    };
    let dialogRef = this.dialog.open(GpEnrollmentNoticeComponent, dialogConfig);
    return dialogRef.afterClosed().pipe(map(isEnrolled =>  isEnrolled ? true : false))
  }

  saveClaims(user: Claim[]): void {
    this.store.dispatch(
      STSActions.loadProfileSuccess({
        claims: user,
      })
    );
  }
}
