import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { shareReplay, tap, catchError } from "rxjs/operators";
import { APP_BASE_HREF } from "@angular/common";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { STSActions } from "src/app/@store/actions/sts.actions";

export interface EnvConfig {
  ApplicationName: "plasma-ui";
  Environment: string;
  Version: string;
  Type: string;
  Country: string;
  baseAuthUrl: string;
  baseAdminUrl: string;
  baseProfileUrl: string;
  baseResultsApiUrl: string;
  baseBookingUrl: string;
  baseBookingAdminUrl: string;
  baseBigcommerceUrl: string;
  EeBookHomeTestUrl?: string;
  EeBookConsultationUrl?: string;
  EeBaseBookingUrl?: string;
  basePlasmaUrl: string;
  baseWebreqUrl: string;
  elasticApmUrl: string;
  elasticApmServiceName: string;
  IsSubFolderDeployment: string;
  ExcludedList: string;
  jsreportUrl: string;
  WhiteLabelEnabled: string;
  WhiteLabelName: string;
  bookingLinkEnabled: string;
  IdSrvClientClientId: string;
  IdSrvClientResponseType: string;
  ParentChildResultViewEnabled: string;
  OtpExpiryInSeconds: string;
  AllowedGenerationIntervalInSeconds: string;
  EnablePreventionWellness: string;
  PreventionWellnessAuthTokenUserIdProperty?: string; //GenePlanet
  FullCatalogueViewEnabled: string;
  ResultsHistoryViewEnabled: string;
  AvailableLanguage: string;
  DefaultLanguage: string;
  RequireMFA: string;
  eeIdleTimeOutInSeconds: number;
  GoogleTagId?: string;
  EnableGoogleTag?: string;
  UseNewUI: string;
  EnableCheckResults: string;
  FICookieManagerId: string;
  EnableFICookieManager: string;
}

export interface IEnvJson {
  OIDC: { ClientId: string; ResponseType: string; Scope: string; };
  ApplicationInsights: { InstrumentationKey: string };
  ApplicationMonitoring: { Enabled: boolean; ApplicationName: string };
}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  /** value to check in Env config `Type` that if matches, should redirect to user's country */
  readonly TypeValueRedirect = "init";
  /** value of Env config `Type` when user has been directed to a country route. */
  readonly TypeValueNormal = "Normal";
  /** store for environment config */
  public envConfig$: Observable<EnvConfig>;

  public _envConfig: EnvConfig;
  private _envConfigError: string = "Env config not loaded!";

  public envJson: IEnvJson = null;

  constructor(
    @Inject(APP_BASE_HREF) private baseHref: string,
    private http: HttpClient,
    private store: Store<AppState>
  ) {}

  /** Preload config data. called in an app initializer.
   * return as promise.
   */
  load(): Promise<EnvConfig> {
    return this.getEnvironmentConfig().toPromise();
  }

  loadEnvJson() {
    return this.http.get("/assets/env.json").toPromise().then(
      (env:IEnvJson) => { this.envJson = env },
      error => { throw error; });
  }

  public async getAvailableLocales() {
    const locales = await this.http.get<({ language: string; cultureCode: string; })[]>(`${this.baseAuthUrl}/manage/locale`).toPromise();
    return locales.map(locale => locale.cultureCode);
  }

  // get environment config
  getEnvironmentConfig(): Observable<EnvConfig> {
    if (this.envConfig$) {
      return this.envConfig$;
    } else {
      let baseHrefCountry = this.baseHref.replace("/", "");
      baseHrefCountry = baseHrefCountry ? baseHrefCountry + "/" : "";

      this.envConfig$ = this.http
        .get<EnvConfig>(`/${baseHrefCountry}api/environment`)
        .pipe(
          tap((envConfig) => {
            this._envConfig = envConfig;
            this.store.dispatch(
              STSActions.loadConfigSuccess({ data: envConfig })
            );
          }),
          catchError((err) => {
            console.error(err);
            return of(undefined);
          }),
          shareReplay(1)
        );
      return this.envConfig$;
    }
  }

  get baseAuthUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.baseAuthUrl;
  }

  get baseClientUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.baseAdminUrl;
  }

  get baseAccountUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.baseProfileUrl;
  }

  get basePlasmatUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.basePlasmaUrl;
  }

  get baseResultsUrl() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.baseResultsApiUrl;
  }

  get environment() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig;
  }

  get version() {
    if (!this._envConfig) {
      throw Error(this._envConfigError);
    }
    return this._envConfig.Version;
  }
}
