import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate() {
    let isLoggedIn = this.authService.isLoggedInObs();

    isLoggedIn.subscribe((loggedin) => {
      if (!loggedin) {
        this.authService.signinRedirectTrigger$.next(Date.now());
      }
    });
    return isLoggedIn;
  }
}
