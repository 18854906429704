import { createReducer, on } from '@ngrx/store';
import dayjs from 'dayjs';
import { ParentChildViewResultActions } from 'src/app/@store/actions/parent-child-view-result.actions';
import { OtpType } from 'src/app/models/otp.model';
import { WardResult, WardResultValidationInfoResponse } from 'src/app/models/ward-result.model';

export const parentChildViewResultFeatureKey = 'parentChildViewResult';

export interface State {
  securityInfoLoading: boolean;
  securityInfoSubmitLoading: boolean;
  securityInfo: WardResultValidationInfoResponse;
  securityInfoError: string;

  wardResult: WardResult;
  wardResultError: string;

  securityInfoRefreshLoading: boolean;

  otpGenerating: boolean;
  otpGeneratedDate: string;
  otpTypeSelected: OtpType;
  otpGenerateError: string;

  getCertLoading: boolean;
  getCertError: string;
}

export const initialState: State = {
  securityInfoLoading: false,
  securityInfoSubmitLoading: false,
  securityInfo: undefined,
  securityInfoError: undefined,
  wardResult: undefined,
  wardResultError: undefined,
  securityInfoRefreshLoading: false,
  otpGenerating: false,
  otpGeneratedDate: undefined,
  otpTypeSelected: undefined,
  otpGenerateError: undefined,
  getCertLoading: false,
  getCertError: undefined,
};


export const reducer = createReducer(
  initialState,
  on(ParentChildViewResultActions.reset, () => initialState),
  on(ParentChildViewResultActions.loadSecurityInfo, (state) => ({
    ...state,
    securityInfoLoading: true,
    securityInfo: undefined,
    securityInfoError: undefined
  })),
  on(ParentChildViewResultActions.loadSecurityInfoSuccess, (state, action) => ({
    ...state,
    securityInfoLoading: false,
    securityInfo: action.data,
    securityInfoError: undefined
  })),
  on(ParentChildViewResultActions.loadSecurityInfoFailure, (state, action) => ({
    ...state,
    securityInfoLoading: false,
    securityInfo: undefined,
    securityInfoError: action.error
  })),
  on(ParentChildViewResultActions.loadResultInfo, (state, action) => ({
    ...state,
    securityInfoSubmitLoading: true,
    wardResult: undefined,
    wardResultError: undefined
  })),
  on(ParentChildViewResultActions.loadResultInfoSuccess, (state, action) => ({
    ...state,
    securityInfoSubmitLoading: false,
    wardResult: action.data,
    wardResultError: undefined
  })),
  on(ParentChildViewResultActions.loadResultInfoFailure, (state, action) => ({
    ...state,
    securityInfoSubmitLoading: false,
    wardResult: undefined,
    wardResultError: action.error
  })),
  on(ParentChildViewResultActions.refreshSecurityInfo, (state, action) => ({
    ...state,
    securityInfoRefreshLoading: true
  })),
  on(ParentChildViewResultActions.refreshSecurityInfoSuccess, (state, action) => ({
    ...state,
    securityInfoRefreshLoading: false,
    securityInfo: action.data,
  })),
  on(ParentChildViewResultActions.refreshSecurityInfoFailure, (state, action) => ({
    ...state,
    securityInfoRefreshLoading: false,
    securityInfoError: action.error
  })),
  on(ParentChildViewResultActions.decrementAttemptsRemaining, (state) => ({
    ...state,
    securityInfo: {
      ...state.securityInfo,
      requestCount: state.securityInfo?.requestCount - 1
    }
  })),
  on(ParentChildViewResultActions.generateOtp, (state, action) => ({
    ...state,
    otpTypeSelected: action?.request?.sms ? 'sms' : 'email',
    otpGeneratedDate: undefined,
    otpGenerating: true,
    otpGenerateError: undefined
  })),
  on(ParentChildViewResultActions.generateOtpSuccess, (state) => ({
    ...state,
    otpGeneratedDate: dayjs.utc().toISOString(),
    otpGenerating: false,
    otpGenerateError: undefined
  })),
  on(ParentChildViewResultActions.generateOtpFailure, (state, action) => ({
    ...state,
    otpGeneratedDate: undefined,
    otpGenerating: false,
    otpGenerateError: action.error
  })),
  on(ParentChildViewResultActions.getCertificate, (state) => ({
    ...state,
    getCertLoading: true,
    getCertError: undefined
  })),
  on(ParentChildViewResultActions.getCertificateSuccess, (state) => ({
    ...state,
    getCertLoading: false
  })),
  on(ParentChildViewResultActions.getCertificateFailure, (state, action) => ({
    ...state,
    getCertLoading: false,
    getCertError: action?.error
  })),
);
