<div class="container-fluid py-4">
  <section class="my-result">
    <div class="card bx-shdw-1">
      <div class="card-body p-0">
        <ul _ngcontent-goc-c14="" class="nav nav-tabs" id="requisitionTab" role="tablist">
          <li class="nav-item">
            <a aria-controls="sendte" class="nav-link active" data-toggle="tab" href="#sendte" id="sendte-tab"
              role="tab">My Results</a></li>
        </ul>
        <div class="tab-content" id="requisitionTabContent">
          <div class="tab-pane fade show active py-3 pb-5" id="sendte" role="tabpanel" aria-labelledby="sendte-tab">
            <div class="header d-flex px-3">
              <div class="recent">
                <label for="" id="myRecentOrders">My Recent Orders</label>
              </div>
              <div class="ml-3 search-box position-relative d-flex align-items-baseline bg-white rounded-3 px-2 py-1 ">
                <span class="fas fa-search pr-1"></span>
                <input type="text" #searchFocus class="search-box__input outline-none border-0" name="searchTerm"
                  [ngModel]="state.searchTerm" (ngModelChange)="changeSearchTerm($event)"
                  placeholder="Search order ID, doctor or tests" />
              </div>
            </div>
            <div class="cont-main">
              <table class="table table-borderless mb-5" aria-describedby="myRecentOrders">
                <thead class="bg-color-grey-100">
                  <tr>
                    <th class="font-color-primary-600 tracking-tight" scope="col" sortable="clientName">Order Date</th>
                    <th class="font-color-primary-600 tracking-tight" scope="col" sortable="name">Order ID</th>
                    <th class="font-color-primary-600 tracking-tight" scope="col" sortable="clientName">Requesting
                      Doctor</th>
                    <th class="font-color-primary-600 tracking-tight" scope="col" sortable="clientName">Status</th>
                    <th class="font-color-primary-600 tracking-tight" scope="col" sortable="clientName">Lab Results</th>
                  </tr>
                </thead>
                <tbody *ngIf="!state.loading && state.endItemIndex > 0; else loadingTableTemplate">
                  <tr *ngFor="let result of state.data">
                    <td class="p-3 font-weight-600 text-black tracking-tighter">
                      {{ result.commonOrder.dateTimeTransaction | date : 'MM-dd-yyyy' : '+0000' }}
                    </td>
                    <td class="p-3 font-weight-600 text-black tracking-tighter">
                      <ngb-highlight [result]="result.observationRequest?.requisitionNumber" [term]="state.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td class="p-3 font-weight-600 text-black tracking-tighter">
                      <ngb-highlight [result]="result.commonOrder?.provider" [term]="state.searchTerm"></ngb-highlight>
                    </td>
                    <td class="p-3 font-weight-600 text-black tracking-tighter">
                      <ng-container [ngSwitch]="result.observationRequest?.observationResults?.status">
                        <span *ngSwitchCase="1"
                          class="bg-color-green-100 font-color-green-800 px-3 py-2 rounded-full">Verified by
                          doctor</span>
                        <span *ngSwitchCase="2"
                          class="bg-color-yellow-100 font-color-yellow-800 px-3 py-2 rounded-full">Waiting for
                          results</span>
                        <span *ngSwitchCase="3"
                          class="bg-color-primary-100 font-color-primary-700 px-3 py-2 rounded-full">Results
                          received</span>
                        <span *ngSwitchDefault class="bg-color-grey-100 font-color-grey-700 px-3 py-2 rounded-full">
                          -
                        </span>
                      </ng-container>
                    </td>
                    <td class="p-3 font-weight-600 text-black tracking-tighter">
                      <a href="#"> View PDF </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between align-items-baseline px-3 flex-column flex-lg-row">
                <span class="tracking-tight font-weight-600 mb-3 mb-lg-0"
                  *ngIf="!state.loading && state.endItemIndex > 0; else emptySpan;">
                  {{ 'Showing ' + state.startItemIndex + ' to ' + state.endItemIndex + ' out of ' + state.totalCount + ' results' }}
                </span>
                <ngb-pagination *ngIf="state.endItemIndex > 0" [page]="state.page" [pageSize]="state.pageSize"
                  [maxSize]="10" [collectionSize]="state.totalCount" (pageChange)='onPageChange($event)'>
                  <ng-template ngbPaginationPrevious><span class="fas fa-less-than"></span></ng-template>
                  <ng-template ngbPaginationNext><span class="fas fa-greater-than"></span></ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<ng-template #loadingTableTemplate>
  <tbody>
    <tr class="no-hover" [ngSwitch]="listStatus">
      <td colspan="5" class="text-center spinner" *ngSwitchCase="1">
        <p class="py-5">
          <img src="assets/images/spinner.gif" alt="spinner" class="img-fluid spinner" style="max-width: 50px;" />
        </p>
      </td>
      <td colspan="5" class="text-center" *ngSwitchCase="2">
        <p class="py-4 tbl-msg">Your search gave no result...</p>
      </td>
      <td colspan="5" class="text-center" *ngSwitchCase="3">
        <p class="py-4 tbl-msg">No records found</p>
      </td>
    </tr>
  </tbody>
</ng-template>

<ng-template #emptySpan>
  <span></span>
</ng-template>