<h2 mat-dialog-title>{{ 'header.preventionWellness' | transloco }}</h2>
<div class="notice-modal">
  <p>
    {{ 'preventionWellness.agreementNoticeText' | transloco }}
  </p>
  <p>
    <a role="button" href="javascript:void(0);" (click)="showPopup('privacy-policy')">{{ 'preventionWellness.privacyPolicyLabel' | transloco }}</a> &amp;
    <a role="button" href="javascript:void(0);" (click)="showPopup('terms-of-service')">{{ 'preventionWellness.termsOfServiceLabel' | transloco }}</a>
  </p>
  <br>
</div>
<div mat-dialog-actions>
  <button (click)="close()" mat-flat-button>
    {{ 'general.cancelButton' | transloco }}
  </button>
  <ng-template #saveButton>
    <button (click)="save()" mat-raised-button>
      {{ 'general.continueButton' | transloco }}
    </button>
  </ng-template>
  <button mat-raised-button *ngIf="saving$|async; else saveButton">
    <mat-spinner diameter="20" color="accent"></mat-spinner>
  </button>
</div>

<div class="notice-overlay" *ngIf="html$ | async" (click)="closePopup()">
  <div class="notice-container">
    <div class="notice-body" [innerHTML]="sanitizedAgreementHtml$|async"></div>
    <app-spinner *ngIf="loading$ | async" diameter="30"></app-spinner>
    <button class="notice-close" (click)="closePopup()" mat-raised-button>
      {{ 'general.okButton' | transloco }}
    </button>
  </div>
</div>
