import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TransferConfirmDialogComponent } from "./dialog/transfer-confirm-dialog.component";
import { ResultActionsV2 } from "src/app/@store/actions/results.actionsV2";
import { AppState } from "src/app/@store/reducers";
import { Store, select } from "@ngrx/store";
import { ResultV2Selectors } from "src/app/@store/selectors/resultsV2.selectors";
import { Actions, ofType } from "@ngrx/effects";

@Component({
  selector: 'app-results-v2-results-transfer-dialog',
  templateUrl: './results-transfer-dialog.component.html',
  styleUrls: ['./results-transfer-dialog.component.scss']
})
export class ResultsV2TransferDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ResultsV2TransferDialogComponent>,
    private store: Store<AppState>,
    private actions$: Actions,
  ) { }

  form = this.fb.group({
    origin: this.fb.control(''),
    destination: this.fb.control('', [Validators.required]),
    reason: this.fb.control('', [Validators.required, Validators.minLength(3)])
  })

  transferring$ = this.store.pipe(select(ResultV2Selectors.resultsTransferInProgress));

  ngOnInit(): void {
    this.form.get('origin').setValue(this.data);
    this.closeOnTransferComplete();
    this.form.get('destination').valueChanges.subscribe(value => {
      if (!value) {
        const f = this.form.get('reason')
        f.setValue('');
        f.markAsUntouched();
      }
    })
  }

  submit() {
    const { name, synlabId } = this.form.value.destination;
    const displayName = name?.trim().length > 0 ? name : synlabId
    let confirm = this.dialog.open(TransferConfirmDialogComponent, {
      data: displayName,
      width: '360px'
    });

    confirm.afterClosed().subscribe(confirmed => {
      if (confirmed) this.startTransfer()
    })
  }

  startTransfer() {
    const { destination, origin, reason } = this.form.value;
    this.store.dispatch(ResultActionsV2.resultsTransferStart({ origin, destination: destination.synlabId, reason }))
  }

  closeOnTransferComplete() {
    this.actions$.pipe(
      ofType(ResultActionsV2.resultsTransferSuccessful)
    ).subscribe(() => {
      this.dialogRef.close(true)
    })
  }

  clearReason() {
    const f = this.form.get('reason');
    f.patchValue('');
    f.markAsUntouched();
  }
}
