import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotFoundGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const pathname = window.location.pathname;

    // temporary hack. need review.
    if (`${state.url}`.includes('/prevention-wellness')) {
      this.router.navigate(['prevention-wellness'])
    }

    if (pathname && (pathname[pathname.length-1] !== '/')) {
      const redirectTo = window.location.href.replace(pathname, pathname + '/');
      window.location.href = redirectTo;
      return;
    }
    return this.router.createUrlTree(['/home'])
  }

}
