import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { EMPTY, forkJoin, Observable, of } from "rxjs";
import { catchError, filter, finalize, map, mergeMap, switchMap, take, takeUntil, tap, withLatestFrom, } from "rxjs/operators";
import { ApiService } from "src/app/service/api/api.service";
import { ResultActions } from "../actions/results.actions";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import orderBy from "lodash/orderBy";
import * as mime from "mime";
import * as blob from "blob-util";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";
import { isLolliIndividual, Results, schoolTestTypes, testTypes, whiteLabelTestTypes, } from "../models/results.model";
import dayjs from "dayjs";
import { timezone } from "../models/timezone";
import { ResultSelectors } from "../selectors/results.selectors";
import { GreenpassDkRequestModel } from "src/app/models/greenpassDkRequest.model";
import { HttpClient } from "@angular/common/http";
import { SchoolOption } from "src/app/models/school-option.model";
import { getErrorMessage } from "../../shared/utils/error.util";

@Injectable()
export class ResultsEffects {
  testTypes = testTypes;
  selectedSchool$ = this.store.pipe(select(ResultSelectors.getSchoolSelected));
  selectedTeacher$ = this.store.pipe(select(ResultSelectors.getTeacherSelected));
  isLolliUser$ = this.store.pipe(select(StsSelectors.isLolliUser));
  isAppAccessUser$ = this.store.pipe(select(StsSelectors.isAppAccessUser));

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<AppState>,
    private http: HttpClient
  ) {}

  // activated when clicking "show file" on table
  loadLabResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadLabResults),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getApiEndpoint)),
          this.store.pipe(select(StsSelectors.isLolliAdmin)),
          this.store.pipe(select(StsSelectors.isLolliLabAdmin)),
        ),
        switchMap(
          ([{ testRef, testCode, lolliSchoolId }, endpoint, isLolliAdmin, isLolliLabAdmin]) => {
            const params = { qrSize: 72, observationResultSynlabId: testCode };
            const endpointURL = isLolliAdmin || isLolliLabAdmin
              ? `${endpoint}/api/schools/${lolliSchoolId}/results/${testRef}`
              : `${endpoint}/api/results/${testRef}`;
            return this.api.getMethod(endpointURL, params).pipe(
              map((rt) => ({ rt, params, testRef })),
              catchError((e) => {
                this.store.dispatch(
                  ResultActions.loadLabResultsFailed({
                    id: testRef,
                  })
                );
                return EMPTY;
              })
            );
          }
        ),
        tap((results) => {
          const id = results.testRef;
          const { laboratoryResult } = results.rt;
          if (laboratoryResult) {
            const { documents } = laboratoryResult;
            this.checkDocuments({ documents }, "result", id);
          }
        })
      ),
    { dispatch: false }
  );

  loadInvoice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadInvoice),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ invoiceId, testRef }, endpoint]) => {
          const params = { qrSize: 72 };
          return this.api
            .getMethod(`${endpoint}/api/results/invoice/${invoiceId}`, params)
            .pipe(
              map((rt) => ({ rt, params, id: testRef })),
              catchError((e) => {
                this.store.dispatch(
                  ResultActions.loadInvoiceFailed({ id: testRef })
                );
                return EMPTY;
              })
            );
        }),
        tap((doc) => {
          console.log({ doc });
          const { document } = doc.rt;
          this.checkDocuments({ documents: [document] }, "invoice", doc.id);
        })
      ),
    { dispatch: false }
  );

  /**
   * @param doc Object that contains the document's list.
   * @param docType Document type: result or invoice.
   * @param docId Unique id of the test.
   */
  private checkDocuments(
    doc: any,
    docType: "result" | "invoice",
    docId: string
  ) {
    if (doc) {
      const { documents } = doc;
      const docs: any = this.getDocs(documents);
      if (docs) {
        const { subType, data, id } = docs;

        const fileExtension = subType.toLowerCase();
        const mimeType = mime.getType(fileExtension);
        const fileName = `${id}.${fileExtension}`;

        let file = data;
        while (this.isBase64(file)) {
          file = blob.base64StringToBlob(file, mimeType);
        }
        const url = blob.createObjectURL(file);

        // preview or download 
        this.previewDownload(fileExtension, url, fileName, mimeType);

        // trigger success
        this.triggerSuccess(docType, docId);
      } else {
        console.log({ PDFNotAvailable: "'documents' is NULL" });
        this.docsNotAvailable(docType, docId);
      }
    }
  }

  private triggerSuccess(docType: string, docId: string) {
    if (docType === "result") {
      this.store.dispatch(
        ResultActions.loadLabResultsSuccess({ id: docId })
      );
    } else {
      this.store.dispatch(ResultActions.loadInvoiceSuccess({ id: docId }));
    }
  }

  private previewDownload(fileExtension:string, url: string, fileName: string, mimeType: string) {
    if (fileExtension === "pdf") {
      this.store.dispatch( ResultActions.loadPdfPreview({ url, filename: fileName, fileType: mimeType, }) );
    } else {
      this.startDownload(url, fileName);
    }
  }

  private getDocs(documents: any[]) {
    return documents && documents.length ? documents[0] : null;
  }

  private docsNotAvailable(docType: string, docId: string) {
    if(docType === "result") {
      this.showCertificateWhenLabResultsPDFNotAvailable(docId)
    } else {
      this.store.dispatch(ResultActions.loadInvoiceFailed({ id: docId }));
    }
  }

  private showCertificateWhenLabResultsPDFNotAvailable(refId: string) {
    this.store.dispatch(ResultActions.loadLabResultsFailed({ id: refId }));

    // show certificate -> https://sldev.atlassian.net/browse/SA-284
    this.isLolliUser$
      .pipe(take(1),withLatestFrom(this.isAppAccessUser$), filter(x => x.some(Boolean)))
      .subscribe(() => this.store.dispatch(ResultActions.forceLoadCertificate({ refId })));
  }

  loadTestTypeList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadTestTypeList),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.isDKTenant)),
          this.store.pipe(select(StsSelectors.isSchoolUser)),
          this.store.pipe(select(StsSelectors.isLolliAdmin)),
          this.store.pipe(select(StsSelectors.isLolliLabAdmin)),
          this.store.pipe(select(StsSelectors.isMassTester)),
          this.store.pipe(select(StsSelectors.isMassCompany))
        ),
        switchMap(([_action, isDKTenant, schoolUser, lolliA, lolliLabAdmin, massTester, hasMassCompanyUsertype]) => {
          if (isDKTenant) {
            return of(whiteLabelTestTypes);
          } else if (schoolUser || lolliA || lolliLabAdmin || massTester || hasMassCompanyUsertype) {
            return of(schoolTestTypes);
          } else {
            return of(testTypes);
          }
        }),
        tap((types) => {
          const t = orderBy(types, [(n) => n.name], ["asc"]);
          this.store.dispatch(
            ResultActions.loadTestTypeListSuccess({
              data: t,
            })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            ResultActions.loadTestTypeListFailure({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  loadResultss$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadResultss),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getApiEndpoint)),
          this.store.pipe(select(StsSelectors.isSchoolAdmin)),
          this.store.pipe(select(StsSelectors.isLolliAdmin)),
          this.store.pipe(select(StsSelectors.isLolliLabAdmin)),
          this.store.pipe(select(StsSelectors.isSchoolTeacher))
        ),
        switchMap(
          ([
            { params, dateFrom, id },
            endpoint,
            isSchoolAdmin,
            isLolliAdmin,
            isLolliLabAdmin,
            isSchoolTeacher,
          ]) => {
            let path = `${endpoint}/api/results`;
            if (isSchoolAdmin || isSchoolTeacher) {
              path = `${endpoint}/api/order-results`;
            }
            if (isLolliAdmin || isLolliLabAdmin) {
              // if school_id is empty, return empty result
              if (!id) {
                return of([]);
              }
              path = `${endpoint}/api/schools/${id}/results`;
            }

            return this.api
              .getMethod(path, params)
              .pipe(map((rt) => ({ rt, params })));
          }
        ),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.isDKTenant))
        ),
        tap(([results, isDKTenant]) =>
          this.saveResults(results, isDKTenant)
        ),
        catchError((error, caught) => {
          this.store.dispatch(
            ResultActions.loadResultssFailure({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  loadCheckResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadCheckResults),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ params, isEmailUsed }, endpoint]) => {
          const { criteria, pageSize, page, observationResultSynlabId } =
            params;
          const query = { pageSize, page, observationResultSynlabId };
          if (isEmailUsed) {
            return this.api
              .getMethod(
                `${endpoint}/api/results?isAdmin=true&email=${encodeURIComponent(
                  criteria
                )}`,
                query
              )
              .pipe(map((data) => ({ data, params })));
          } else if (criteria && !this.hasWhiteSpace(criteria)) {
            return this.api
              .getMethod(
                `${endpoint}/api/results?isAdmin=true&synlabId=${criteria}`,
                query
              )
              .pipe(map((data) => ({ data, params })));
          } else {
            return of(null);
          }
        }),
        tap((results) => this.saveCheckResultList(results)),
        catchError((error, caught) => {
          this.store.dispatch(
            ResultActions.loadCheckResultsFailed({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  loadSelectedUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadSelectedUser),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ criteria }, endpoint]) => {
          return this.api.getMethod(`${endpoint}/api/user/${criteria}`);
        }),
        tap((results) => {
          console.log(results);
          this.store.dispatch(
            ResultActions.loadSelectedUserSuccess({
              data: results,
            })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            ResultActions.loadSelectedUserFailed({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  loadSelectedUserSynlabId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadSelectedUserSynlabId),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ criteria }, endpoint]) => {
          return this.api.getMethod(
            `${endpoint}/api/user/synlabId/${criteria}`
          );
        }),
        tap((results) => {
          console.log(results);
          this.store.dispatch(
            ResultActions.loadSelectedUserSynlabIdSuccess({
              data: results,
            })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            ResultActions.loadSelectedUserSynlabIdFailed({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.downloadFile),
        mergeMap(({ id }) => {
          return this.api.getFile(`/results/${id}/documents/latest`).pipe(
            tap((x) => {
              const mimeType = x.headers.get("content-type");
              const fileExtension = mime.getExtension(mimeType);
              const fileName =
                x.headers
                  .get("content-disposition")
                  .match(/(?=filename=)(.*?)(?=; )/g)[0]
                  ?.replace("filename=", "") +
                "." +
                fileExtension;

              let file = x.body;
              while (this.isBase64(file)) {
                file = blob.base64StringToBlob(x.body, mimeType);
              }
              const url = blob.createObjectURL(file);

              /** preview or download */
              if (fileExtension === "pdf") {
                this.store.dispatch(
                  ResultActions.loadPdfPreview({
                    url,
                    filename: fileName,
                    fileType: mimeType,
                  })
                );
              } else {
                this.startDownload(url, fileName);
              }

              this.store.dispatch(ResultActions.downloadFileSuccess({ id }));
            }),
            catchError((error) => {
              this.store.dispatch(
                ResultActions.downloadFileFailure({
                  id,
                  error,
                })
              );
              return EMPTY;
            })
          );
        })
      ),
    { dispatch: false }
  );

  loadSchool$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadSchool),
        switchMap(({ params }) => {
          return this.fetchSchool(params).pipe(
            takeUntil(
              this.actions$.pipe(
                ofType(ResultActions.clearLoadSchool),
                take(1)
              )
            )
          );
        }),
        tap((res) => {
          this.store.dispatch(
            ResultActions.loadSchoolSuccess({
              data: res,
            })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            ResultActions.loadSchoolFailed({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );
  loadTeacher$ = createEffect(
    () => this.actions$.pipe(
      ofType(ResultActions.loadTeacher),
      switchMap(({ schoolSynlabId }) => {
        return this.fetchTeacher(schoolSynlabId).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(ResultActions.LoadTeacherCancel),
              take(1)
            )
          ),
          tap((res) => {
            this.store.dispatch(ResultActions.fetchTeacherSuccess({ data: res }))
            this.store.dispatch(ResultActions.loadTeacherSuccess());
          }),
          catchError(error => {
            this.store.dispatch(
              ResultActions.loadTeacherFailed({ error: getErrorMessage(error)})
            );
            return EMPTY;
          })
        );
      })
    ),
    { dispatch: false }
  );
  loadPool$ = createEffect(
    () => this.actions$.pipe(
      ofType(ResultActions.loadPool),
      switchMap((action) => {
        const schoolSynlabId = action?.schoolSynlabId,
          teacherSynlabId = action?.teacherSynlabId
        if(!schoolSynlabId) {
          this.store.dispatch(ResultActions.LoadPoolCancel());
          return;
        }
        return this.fetchPool(schoolSynlabId, teacherSynlabId).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(ResultActions.LoadPoolCancel),
              take(1)
            )
          ),
          tap(res => {
            this.store.dispatch(ResultActions.fetchPoolSuccess({ data: res }));
            this.store.dispatch(ResultActions.loadPoolSuccess());
          }),
          catchError(error => {
            this.store.dispatch(
              ResultActions.loadPoolFailed({ error: getErrorMessage(error) })
            );
            return EMPTY;
          })
        )
      })
    ),
    { dispatch: false }
  );

  loadGreenpass$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.loadGreenpass),
        mergeMap(({ result }) => {
          const request: GreenpassDkRequestModel = {
            DateOfBirth: dayjs
              .utc(result.dateOfBirth, "DD.MM.YYYY")
              .toISOString(),
            FamilyName: result.lastName,
            GivenName: result.firstName,
            Manufacturer: result.manufacturerId ?? "",
            PersonIdentifier: result.synlabId ?? "",
            ResultType: +result.synlabValue,
            TestingCenter: result.laboratory,
            samplingDate: result.samplingTime
              ? dayjs
                  .tz(result.samplingTime, "DD.MM.YYYY HH:mm", timezone)
                  .toISOString()
              : "",
            testCode: result.testCode,
          };
          return this.requestGreenpass(request).pipe(
            tap((response) => {
              this.store.dispatch(
                ResultActions.loadGreenpassSuccess({
                  greenpassQrData: response,
                  result,
                })
              );
            }),
            catchError((error, caught) => {
              this.store.dispatch(
                ResultActions.loadgreenpassFailure({
                  error,
                  result,
                })
              );
              return EMPTY;
            })
          );
        })
      ),
    { dispatch: false }
  );
  batchLoadGreenpass$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActions.batchLoadGreenpass),
        mergeMap(({ results }) => {
          const testRefIds = results.map((x) => x.testReference);
          const greenPassResponseActions = testRefIds.map((testRefId) =>
            this.actions$.pipe(
              ofType(
                ResultActions.loadGreenpassSuccess,
                ResultActions.loadgreenpassFailure
              ),
              filter(
                (greenpassResponseAction) =>
                  greenpassResponseAction.result.testReference === testRefId
              ),
              take(1)
            )
          );
          const greenpassResponses = forkJoin(greenPassResponseActions);
          results.forEach((result) =>
            this.store.dispatch(ResultActions.loadGreenpass({ result }))
          );
          return greenpassResponses;
        }),
        tap((responseList) => {
          responseList.some(
            (x) => x.type === ResultActions.loadgreenpassFailure.type
          )
            ? this.store.dispatch(
                ResultActions.batchLoadGreenpassFailure({
                  error: "Failed to fetch one or more greenpass data",
                  results: responseList.filter((x) => x.type === ResultActions.loadgreenpassFailure.type).map(r => r.result),
                })
              )
            : this.store.dispatch(ResultActions.batchLoadGreenpassSuccess());
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            ResultActions.batchLoadGreenpassFailure({ error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );
  checkCompany$ = createEffect(
    () => this.actions$.pipe(
      ofType(ResultActions.loadCompanyCheck),
      withLatestFrom(this.store.pipe(select(ResultSelectors.getCompanyCheckRecord))),
      mergeMap(([action, record]) => {
        return of(...action.poolSynlabIds.filter(id => record[id] === undefined)).pipe(
          mergeMap((poolSynlabId) => this.fetchCompanyCheck(poolSynlabId).pipe(
            map(res => [poolSynlabId, res] as const)
          ), 2),
          tap(([poolSynlabId,res]) => this.store.dispatch(ResultActions.fetchCompanyCheckSuccess({
            companyCheckResponse: res,
            poolSynlabId: poolSynlabId
          }))),
          finalize(() => this.store.dispatch(ResultActions.loadCompanyCheckDone()))
        )
      })
    ),
    { dispatch: false }
  )

  checkIfPool$ = createEffect(() => this.actions$.pipe(
    ofType(ResultActions.checkIfPool),
    withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
    mergeMap(([{ synlabId }, endpoint]) => this.api.getMethod<boolean>(`${endpoint}/api/results/${synlabId}/checkifpool`).pipe(
      map(res => ResultActions.checkIfPoolSuccess({ synlabId, isPool: res })),
      catchError(err => {
        const msg = getErrorMessage(err);
        return of(ResultActions.checkIfPoolFailure({ synlabId, error: msg }));
      })
    ))
  ));

  getLabAddress$ = createEffect(() => this.actions$.pipe(
    ofType(ResultActions.getLabAddress),
    withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
    mergeMap(([{ senderIdentifierCode }, endpoint]) => this.api.getForResponseTypeText<any>(`${endpoint}/api/results/laboratory/code/${senderIdentifierCode}/address`).pipe(
      map(res => ResultActions.getLabAddressSuccess({ senderIdentifierCode, laboratoryAddress: res })),
      catchError(err => {
        const msg = getErrorMessage(err);
        return of(ResultActions.getLabAddressFailure({ senderIdentifierCode, error: msg }));
      })
    ))
  ));

  getResultAddress$ = createEffect(() => this.actions$.pipe(
    ofType(ResultActions.getResultAddress),
    withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
    mergeMap(([{ synlabId }, endpoint]) => this.api.getMethod<any>(`${endpoint}/api/profiles/certinfo/${synlabId}`).pipe(
      map(res => {
        return ResultActions.getResultAddressSuccess({ synlabId, data: res });
      }),
      catchError(err => {
        const msg = getErrorMessage(err);
        return of(ResultActions.getResultAddressFailure({ synlabId, error: msg }));
      })
    ))
  ));

  private requestGreenpass(request: GreenpassDkRequestModel) {
    return of(request).pipe(
      withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
      mergeMap(([req, endpoint]) => {
        return this.http.post<string>(
          `${endpoint}/api/dksigning/greenpass/dk`,
          req
        );
      })
    );
  }

  private isBase64(file: string) {
    try {
      return Boolean(atob(file));
    } catch (ex) {
      return false;
    }
  }

  private startDownload(url: string, filename: string) {
    const link = document.createElement("a");
    link.setAttribute("download", filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  private getResult(value) {
    switch (value) {
      case 1:
      case "1":
        return "Negative";
      case 2:
      case "2":
        return "Positive";
      case "3":
      case "4":
      default:
        return "Inconclusive";
    }
  }

  saveResults(d, isDKTenant: boolean) {
    const { rt, params } = d;

    const { results, totalCount } = rt;
    const total = totalCount || 0;
    const r = this.getSortedResults(results);

    let data = r.map((n: Results, i) => ({
      ...n,
      id: n.testReference,
      result: this.getResult(n.synlabValue),
      samplingTime: this.getFormattedDate(n.samplingTime, true),
      issuedOn: this.getFormattedDate(n.issuedOn, true),
      issuedOnDateOnly: this.getFormattedDate(n.issuedOn),
      dateOfBirth: this.getFormattedDate(n.dateOfBirth),
      isNegative: n.synlabValue == "1",
      hasExpired: this.checkExpiry(isDKTenant, n.samplingTime),
      testTubeIdentifiers: n.testTubeIdentifiers?.join(", ") || "",
      isLolliIndividualResult: isLolliIndividual(n)
    }));

    this.store.dispatch(
      ResultActions.loadResultssSuccess({ data, total, params })
    );
  }

  private checkExpiry(isDk: boolean, samplingTime?: string): boolean{
    if (isDk) {
      return false;
    } 

    if (samplingTime) {
      return this.checkExpired(samplingTime);
    }
    return null;
  }

  private getFormattedDate(date: string, withTime?: boolean): string {
    const dateFormat = withTime ? "DD.MM.YYYY HH:mm": 'DD.MM.YYYY';
    return date ? dayjs(date).tz(timezone).format(dateFormat) : null;
  }

  private getSortedResults(results: Results[]) {
    return results && results.length
        ? reverse(sortBy(results, ["issuedOn", "samplingTime"]))
        : [];
  }

  saveCheckResultList(d) {
    const { data, params } = d;
    const { results, totalCount } = data;
    const total = totalCount || 0;

    const r = results && results.length ? results : [];
    const list = r.map((n: Results, i) => ({
      ...n,
      result: this.getResult(n.synlabValue),
      samplingTime: n.samplingTime
        ? dayjs(n.samplingTime).tz(timezone).format("DD.MM.YYYY HH:mm")
        : null,
      issuedOn: n.issuedOn
        ? dayjs(n.issuedOn).tz(timezone).format("DD.MM.YYYY HH:mm")
        : null,
      dateOfBirth: n.dateOfBirth
        ? dayjs.tz(n.dateOfBirth, timezone).format("DD.MM.YYYY")
        : null,
    }));

    this.store.dispatch(
      ResultActions.loadCheckResultsSuccess({ list, total, params })
    );
  }

  private hasWhiteSpace(s) {
    console.log(/\s/g.test(s));
    return /\s/g.test(s);
  }

  private checkExpired(date) {
    return dayjs().tz(timezone) > dayjs(date).tz(timezone).add(7, "day")
      ? true
      : false;
  }
  fetchCompanyCheck(poolSynlabId: string) {
    return this.store.pipe(select(StsSelectors.getApiEndpoint)).pipe(
      take(1),
      mergeMap(endpoint => this.api.getMethod<{
        companyName: string,
        isPoolCompany: boolean
      }>(`${endpoint}/api/results/poolbarcode/${poolSynlabId}/check`))
    );
  }
  fetchSchool(params: string) {
    return this.store.pipe(select(StsSelectors.getApiEndpoint)).pipe(
      take(1),
      mergeMap(endpoint => this.api.getMethod<SchoolOption[]>(
        `${endpoint}/api/lolliusermanagement/search/school`,
        { searchText: encodeURIComponent(params) }
      ))
    )
  }
  private fetchTeacher(
    schoolSynlabId: string
  ): Observable<SchoolOption[]> {
    return this.store.pipe(select(StsSelectors.getApiEndpoint)).pipe(
      take(1),
      mergeMap(endpoint => this.api.getMethod<SchoolOption[]>(
        `${endpoint}/api/schools/teacher`,
        { schoolSynlabId }
      ))
    )
  }
  private fetchPool(
    schoolSynlabId: string,
    teacherSynlabId: string
  ): Observable<SchoolOption[]> {
    const teacherSynlabIdPartial = teacherSynlabId
      ? {teacherSynlabId}
      : {};
    return this.store.pipe(select(StsSelectors.getApiEndpoint)).pipe(
      take(1),
      mergeMap(endpoint => this.api.getMethod<SchoolOption[]>(
        `${endpoint}/api/schools/pool`,
        {
          schoolSynlabId,
          ...teacherSynlabIdPartial
        }
      ))
    )
  }
}
