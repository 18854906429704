import { createReducer, on } from "@ngrx/store";
import { ChildInfo, Pool, PoolIndividuals, PoolTempOrders, } from "src/app/pages/individual-test/orders/data-model";
import { IndividualOrderActions } from "./individual-order.actions";

export const individualOrderFeatureKey = "individualOrder";

export interface State {
  poolInfo: Pool;
  poolInfoInProgress: boolean;
  poolInvalid: boolean;
  poolInfoStatus: "error" | "success" | "hasNegative" | null;
  childInfo: ChildInfo;
  childInfoInProgress: boolean;
  childInfoStatus: "error" | "success" | null;
  sendOrderInProgress: boolean;
  checkTestTubeInprogress: boolean;
  setNextPersonButtonAsDisabled: boolean;
  poolIsNegative: boolean;
  individuals: PoolIndividuals;
  tempOrders: PoolTempOrders[];
}

export const initialState: State = {
  poolInfo: null,
  poolInfoInProgress: false,
  poolInvalid: false,
  poolInfoStatus: null,
  childInfo: null,
  childInfoInProgress: false,
  childInfoStatus: null,
  sendOrderInProgress: false,
  checkTestTubeInprogress: false,
  setNextPersonButtonAsDisabled: false,
  poolIsNegative: null,
  individuals: {
    schoolSynlabId: "",
    individuals: [],
  },
  tempOrders: [],
};

const individualsInit = {
  individuals: [],
  schoolSynlabId: "",
};

export const reducer = createReducer(
  initialState,
  on(IndividualOrderActions.clearPool, (state) => ({
    ...state,
    poolInfo: null,
    poolInfoInProgress: false,
    poolInvalid: false,
    poolInfoStatus: null,
  })),
  on(IndividualOrderActions.checkPool, (state) => ({
    ...state,
    poolInfoInProgress: true,
    poolInvalid: false,
    poolInfoStatus: null,
  })),
  on(IndividualOrderActions.checkPoolSuccess, (state, { data }) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfo: data,
    poolInvalid: false,
    poolInfoStatus: "success",
  })),
  on(IndividualOrderActions.checkPoolError, (state, { error }) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfo: null,
    poolInvalid: true,
    poolInfoStatus: "error",
  })),
  on(IndividualOrderActions.checkChild, (state) => ({
    ...state,
    childInfo: null,
    childInfoInProgress: true,
    childInfoStatus: null,
    setNextPersonButtonAsDisabled: true,
  })),
  on(IndividualOrderActions.checkChildSuccess, (state, { data }) => ({
    ...state,
    childInfo: data,
    childInfoInProgress: false,
    childInfoStatus: "success",
  })),
  on(IndividualOrderActions.checkChildError, (state, { error }) => ({
    ...state,
    childInfo: null,
    childInfoInProgress: false,
    childInfoStatus: "error",
  })),
  on(
    IndividualOrderActions.clearChild,
    IndividualOrderActions.childExistsError,
    (state) => ({
      ...state,
      childInfo: null,
      childInfoInProgress: false,
      childInfoStatus: null,
    })
  ),

  on(IndividualOrderActions.submit, (state) => ({
    ...state,
    sendOrderInProgress: true,
  })),
  on(
    IndividualOrderActions.submitSuccess,
    IndividualOrderActions.submitFailed,
    (state) => ({
      ...state,
      sendOrderInProgress: false,
    })
  ),
  on(IndividualOrderActions.checkDuplicate, (state) => ({
    ...state,
    checkTestTubeInprogress: true,
    setNextPersonButtonAsDisabled: true,
  })),
  on(
    IndividualOrderActions.proceedCreateOrder,
    IndividualOrderActions.stopCreateOrder,
    (state) => ({
      ...state,
      checkTestTubeInprogress: false,
    })
  ),
  on(IndividualOrderActions.proceedCreateOrder, (state) => ({
    ...state,
    setNextPersonButtonAsDisabled: false,
  })),
  on(IndividualOrderActions.stopCreateOrder, (state) => ({
    ...state,
    setNextPersonButtonAsDisabled: true,
  })),

  on(IndividualOrderActions.hasNegativeResults, (state) => ({
    ...state,
    poolIsNegative: null,
    poolInfoInProgress: true,
    poolInfoStatus: null,
  })),

  // check pool if hasNegative value
  on(IndividualOrderActions.hasNegativeResultsSuccess, (state, { value }) => ({
    ...state,
    poolIsNegative: value,
    poolInfoInProgress: false,
    poolInfoStatus: value ? "hasNegative" : null,
  })),
  on(IndividualOrderActions.hasNegativeResultsFailed, (state) => ({
    ...state,
    poolIsNegative: null,
    poolInfoInProgress: false,
    poolInfoStatus: null,
  })),

  // get pool information
  on(IndividualOrderActions.getPoolInformation, (state) => ({
    ...state,
    poolInfoInProgress: true,
    poolInfoStatus: null,
  })),
  on(IndividualOrderActions.getPoolInformationSuccess, (state, { data }) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfo: data,
    // poolInfoStatus: "success",
  })),
  on(IndividualOrderActions.getPoolInformationFailed, (state) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfo: null,
    // poolInfoStatus: "error",
  })),

  // get pool count
  on(IndividualOrderActions.getPoolCountNormal, (state) => ({
    ...state,
    poolInfoInProgress: true,
    poolInfoStatus: null,
    individuals: individualsInit,
  })),
  on(IndividualOrderActions.getPoolCountNormalSuccess, (state, { data }) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfoStatus: null,
    individuals: data,
  })),
  on(IndividualOrderActions.getPoolCountNormalFailed, (state, { error }) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfoStatus: null,
    individuals: individualsInit,
  })),

  on(IndividualOrderActions.getPoolCountCompany, (state) => ({
    ...state,
    poolInfoInProgress: true,
    poolInfoStatus: null,
    individuals: individualsInit,
    tempOrders: [],
  })),
  on(IndividualOrderActions.getPoolCountCompanySuccess, (state, { data }) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfoStatus: null,
    tempOrders: data,
  })),
  on(IndividualOrderActions.getPoolCountCompanyFailed, (state, { error }) => ({
    ...state,
    poolInfoInProgress: false,
    poolInfoStatus: null,
    tempOrders: [],
  }))
);
