import { CommonModule } from "@angular/common";
import { Component, NgModule, Inject } from "@angular/core";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "src/app/service/api/api.service";
import { GpEnrollee } from "./gp-enrollee.model";
import { map, take } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import DOMPurify from "dompurify";
import { SpinnerModule } from "src/app/plasma-ui-common/synlab-access-ui-components/spinner/spinner.component";



@Component({
  selector: 'app-gp-enrollment-notice',
  styleUrls: ['./gp-enrollment-notice.component.scss'],
  templateUrl: './gp-enrollment-notice.component.html'
})

export class GpEnrollmentNoticeComponent {

  saving$ = new BehaviorSubject(false);
  loading$ = new BehaviorSubject(false);
  html$ = new BehaviorSubject('');

  sanitizedAgreementHtml$ = this.html$.pipe(
    map(html => {
      const purified = DOMPurify.sanitize(
        html,
        {
          ADD_TAGS: ['link'],
          ADD_ATTR: ['href', 'rel'],
          FORCE_BODY: true
        }
      );
      return this.sanitizer.bypassSecurityTrustHtml(purified);
    })
  )

  readonly agreementType = {
    'terms-of-service': 2,
    'privacy-policy': 1
  }

  readonly localeMap = {
    'et': 'et-EE',
    'ru': 'ru-RU',
    'en': 'en-US'
  }

  constructor(
    private dialogRef: MatDialogRef<GpEnrollmentNoticeComponent>,
    private api: ApiService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data: { enrollee: GpEnrollee },
    private translate: TranslocoService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
  ) {

  }

  close() {
    this.dialogRef.close(false);
  }

  showPopup(type: string) {
    let agreementType = 0;
    let locale = this.translate.getActiveLang();
    if (type in this.agreementType) {
      agreementType = this.agreementType[type];
    }
    if (locale in this.localeMap) {
      locale = this.localeMap[locale]
    }
    this.loading$.next(true);
    this.html$.next('<div class="d-none"></div>');
    this.api.get(`/manage/agreement/published/${agreementType}/${locale}`)
      .pipe(take(1))
      .subscribe((res) => {
        this.html$.next(res.files[0]?.htmlFile);
        this.loading$.next(false);
      }, (err) => {
        const message = this.translate.translate('error.ERROR__INTERNALSERVER');
        this.html$.next(`<h2 class="text-danger">${message}</h2>`);
        console.error(err);
        this.loading$.next(false);
    });
  }

  closePopup() {
    this.html$.next('');
  }

  save() {
    this.saving$.next(true);
    this.api.post('/manage/geneplanet/enroll', this.data.enrollee,  {responseType: 'text'})
    .subscribe(
      () => {
        this.dialogRef.close(true);
      },
      () => {
        this.toastr.error(this.translate.translate('error.REQUEST_ERROR'));
        this.dialogRef.close(false);
      }
    );
  }
}

@NgModule({
  imports: [
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    TranslocoModule,
    SpinnerModule,
  ],
  exports: [GpEnrollmentNoticeComponent],
  declarations: [GpEnrollmentNoticeComponent]
})

export class GpEnrollmentNoticeModule {}
